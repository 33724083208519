import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerCurso() {
    var url = ConfigAPI.baseURL + "courses" + Session.getToken();
    return instance.get(url);
  },
  mostrarCurso(id) {
    var url = ConfigAPI.baseURL + "courses/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCurso(data, image1, file1, file2) {      
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }

      if(key=="file") {                
        file1.forEach((value, key) => {          
          formData.append('file', value);
        })
      }

      if(key=="material") {                
        var i = 0
        file2.forEach((value, key) => {          
          formData.append('material_' + i, value);
          i++
        })
        formData.append('material', i);
      }      
    }

    var params = formData
    var url = ConfigAPI.baseURL + "courses" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarCurso(data, image1, file1, file2) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }

      if(key=="file") {                
        file1.forEach((value, key) => {          
          formData.append('file', value);
        })
      }   

      if(key=="material") {              
        var i = 0
        file2.forEach((value, key) => {          
          formData.append('material_' + i, value);
          i++
        })
        formData.append('material', i);
      }        
    }

    var params = formData
    var url = ConfigAPI.baseURL + "courses/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarCurso(id) {
    var url = ConfigAPI.baseURL + "courses/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarCurso(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "courses/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },

  obtenerCategoria() {
    var url = ConfigAPI.baseURL + "courses-category" + Session.getToken();
    return instance.get(url);
  },
  mostrarCategoria(id) {
    var url = ConfigAPI.baseURL + "courses-category/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCategoria(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "courses-category" + Session.getToken();
    return instance.post(url,params);
  },
  editarCategoria(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "courses-category/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCategoria(id) {
    var url = ConfigAPI.baseURL + "courses-category/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerSubcategoria() {
    var url = ConfigAPI.baseURL + "courses-subcategory" + Session.getToken();
    return instance.get(url);
  },
  mostrarSubcategoria(id) {
    var url = ConfigAPI.baseURL + "courses-subcategory/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSubcategoria(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "courses-subcategory" + Session.getToken();
    return instance.post(url,params);
  },
  editarSubcategoria(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "courses-subcategory/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarSubcategoria(id) {
    var url = ConfigAPI.baseURL + "courses-subcategory/"+ id + Session.getToken();
    return instance.delete(url);
  },  

  obtenerListaPrecios() {
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.get(url);
  },  
  agregarPrecio(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-courses" + Session.getToken();
    return instance.post(url,params);
  },
  editarPrecio(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-courses/"+ data.id + Session.getToken();
    return instance.post(url,params);    
  },
  eliminarPrecio(id) {       
    var url = ConfigAPI.baseURL + "price-list-courses/"+ id + Session.getToken();
    return instance.delete(url);
  },  
}

export default servicesAPI;
