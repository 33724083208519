<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Cursos</h4>
            <div class="small text-muted">Administración de cursos</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>
      
      <b-row>
        <b-col lg="12" id="view_filters_courses">    
          <b-card v-if="table.mostrarFiltros" id="list-courses">
            <b-row>
              <b-col sm="4">
                <v-select :options="arr.select.categorys" v-model="filters.categorys" placeholder="Categorías" :multiple="false" :select-on-tab="true" @input="filterLoadSubCategorys" ></v-select>
                <v-select :options="arr.select.subcategorys" v-model="filters.subcategorys" placeholder="Subcategorías" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
                                
              <b-col sm="3">
                <v-select :options="arr.select.active" v-model="filters.active" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>  

              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterCourses()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col lg="12" id="view_list_courses">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" 
                                  placeholder="Ingrese su busqueda para filtrar la grilla" 
                                  v-model="table.filter"
                                  size="sm">
                    </b-form-input>              
                  </b-input-group>
                </b-form-group>            
              </b-col>
              <b-col>
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Cursos">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>
              </b-col>
              <b-col sm="12">
                <b-table class="mb-0 table-courses-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        :busy="table.isBusy"                     
                        v-if="table.items.length || table.isBusy">                                               
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                                                  
                    <template v-slot:cell(id)="data">
                      <b>{{data.item.id}}</b>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div class="crud-courses-title">
                        <span v-if="data.item.code" class="crud-courses-code">
                          {{data.item.code}} 
                        </span><br>
                        {{data.item.name}}
                      </div>
                    </template>

                    <template v-slot:cell(image)="data">
                      <b-img  :src="data.item.image[0]" 
                              fluid 
                              class="crud-course-image" 
                              v-if="data.item.image"/>
                      <b-icon icon="image" 
                              v-else 
                              class="h2" 
                              v-b-tooltip.hover
                              title="Imagen No Disponible" />
                    </template>                
                    
                    <template v-slot:cell(prices)="data">                    
                      <div v-if="data.item.prices.length<3">
                        <div v-for="element in data.item.prices" :key="element.id">
                          <b :title="element.list.name" v-b-tooltip.hover>
                            <span v-if="element.list.reference">
                              {{element.list.reference.substring(0,5)}}
                            </span>
                            <span v-else>
                              {{element.list.name.substring(0,5)}}
                            </span>                              
                          </b> : 
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}
                        </div>
                      </div>
                      <div v-else>
                        <a href="javascript:void(0)" @click="setPricesCourses(data.item)">Ver Listas</a>
                      </div>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableCoursesShow"
                                  @hide="setConfigTableCoursesHide">
                        
                        <b-dropdown-item @click="setPricesCourses(data.item)" v-if="modules.showPrices">
                          <i class="fa fa-dollar crud-course-action-icon"></i> Precios
                        </b-dropdown-item>                              

                        <b-dropdown-header>Acciones</b-dropdown-header>                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>          

      <b-row>
        <b-col lg="12" id="view_footer_courses">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"  
                                @input="filterCourses()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="12" class="mb-4">
                <b-form-checkbox v-model="crud.form.active" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Activar
                </b-form-checkbox>            
              </b-col>                                           
              <b-col md="3">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.code"
                                required
                                placeholder="Ingresar un código">
                  </b-form-input>
                </b-form-group>
              </b-col>                   
              <b-col md="9">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>  
              <b-col md="12">
                <b-form-group label="Descripción Corta">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.description_short"                                
                                placeholder="Ingresar una descripción">
                  </b-form-input>
                </b-form-group>
              </b-col>                 
            </b-row>     

            <b-row>   
              <b-col>
                <FindObject type="coursesSubCategory" 
                            @select-object="loadCoursesSubcategory($event)" 
                            :valueID="crud.form.subcategory_id"/>
              </b-col>                             
            </b-row>          
          </b-tab>
        
          <b-tab no-body title="Detalle">
            <b-row>                               
              <b-col md="4">                
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Galería">
                      <Imagen :images="crud.form.image" 
                              @load-image="crud.form.image = $event" 
                              typeImage="Imagenes" 
                              :multipleImage="true"
                              :maxImage="10" 
                              :compressImage="true"/>
                    </b-form-group>                            
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Adjunto">                
                      <b-form-file v-if="!crud.form.fileLink" 
                                    v-model="crud.form.file"                             
                                    size="sm"
                                    :state="Boolean(crud.form.file)"
                                    placeholder="Elija un archivo o sueltelo aquí..."
                                    drop-placeholder="Suelta el archivo aquí...">
                      </b-form-file>      
                      <div v-else>
                        <b-link :href="crud.form.fileLink" target="_blank">
                          <b>Ver Archivo</b>
                        </b-link>           
                        <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.fileLink=''" v-b-tooltip.hover title="Cargar otro archivo">
                          <i class="fa fa-refresh"></i>
                        </b-button>                                         
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="8">
                <b-row>
                  <b-col md="12">                    
                    <b-form-checkbox v-model="crud.form.featured" 
                                      switch 
                                      size="sm" 
                                      class="pull-right">
                      Destacado
                    </b-form-checkbox>            
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Descripción Larga">
                      <vue-editor v-model="crud.form.description"></vue-editor>                              
                    </b-form-group>      
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Videos">
            <b-button variant="outline-success" @click="addItemVideoCourses()" class="mb-3" size="sm">
              Agregar Video
            </b-button>
            <b-alert show variant="primary">
              <h4 class="alert-heading">Formatos de videos disponibles</h4>
              <p>
                <b-icon icon="chevron-right"></b-icon> Videos con extensiones (mp4, avi, etc).<br>
                <b-icon icon="chevron-right"></b-icon> URL de desde (youtube, vimeo, dailymotion, coub)<br>
                <b-icon icon="chevron-right"></b-icon> iFrame desde cualquier plataforma
              </p>
            </b-alert>

            <b-input-group v-for="(element, index) in videosTMP" v-bind:key="index" class="mt-1" >
              <b-input-group-text>
                <b-icon icon="camera-video" />
              </b-input-group-text>

              <b-form-input type="text" 
                            v-model="videosTMP[index].link"
                            placeholder="URL del video">
              </b-form-input>

              <b-input-group-append>
                <b-button variant="outline-danger" @click="deleteItemVideoCourses(element)" v-b-tooltip.hover title="Eliminar Videos">
                  <b-icon icon="trash" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-tab>

          <b-tab no-body title="Materiales">
            <b-button variant="outline-success" @click="addItemMaterialCourses()" class="mb-3" size="sm">
              Agregar Material
            </b-button>

            <b-input-group v-for="(element, index) in materialTMP" v-bind:key="index" class="mt-1" >
              <b-input-group-text>
                <b-icon icon="paperclip" />
              </b-input-group-text>

              <b-form-input type="text" 
                            v-model="materialNameTMP[index].name"
                            size="md"
                            placeholder="Nombre del Material">
              </b-form-input>

              <b-form-file v-if="!materialTMP[index].fileLink" 
                            v-model="materialTMP[index].file"                             
                            size="sm"
                            :state="Boolean(materialTMP[index].file)"
                            placeholder="Elija un archivo o sueltelo aquí..."
                            drop-placeholder="Suelta el archivo aquí...">
              </b-form-file>      
              <div v-else>
                <b-link :href="materialTMP[index].fileLink" target="_blank" >
                  <b>Ver Archivo</b>
                </b-link>           
                <b-button type="button" 
                          variant="outline-dark" 
                          size="sm" 
                          class="btn-pill ml-2" 
                          @click="changeMaterial(index)" 
                          v-b-tooltip.hover 
                          title="Cargar otro archivo">
                  <i class="fa fa-refresh"></i>
                </b-button>  
              </div>

              <b-input-group-append>
                <b-button variant="outline-danger" @click="deleteItemMaterialCourses(element)" v-b-tooltip.hover title="Eliminar Material">
                  <b-icon icon="trash" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-tab>

          <b-tab no-body title="Fiscal" v-if="isProcessSales">
            <b-row>
              <b-col lg="6">
                <FindObject render="search"
                            type="erpIvaConditions" 
                            @select-object="loadIvaConditions($event)" 
                            :valueID="crud.form.erp_iva_condition_id"/>                   
              </b-col>              
            </b-row>            
          </b-tab>           
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD PRECIOS -->
      <b-modal  v-model="modal.formPrice.active"
                header-bg-variant="dark"
                header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formPrice.title}}
        </div>

        <b-row>         
          <b-col md="12">
            <div class="crud-courses-title" v-if="itemSelected">
              <div class="crud-courses-title">
                <span v-if="itemSelected.code" class="crud-courses-code">
                  {{this.itemSelected.code}} 
                </span><br>
                {{this.itemSelected.name}}
              </div>
              <hr>
            </div>
          </b-col>
          <b-col md="5">
            <b-form-group label="Lista">
              <v-select :options="arr.select.listPrice" 
                        v-model="crud.formPrice.price_list_select" 
                        placeholder="Lista"
                        :disabled="crud.formPrice.id>0">
              </v-select>
            </b-form-group>
          </b-col>    
          <b-col md="4">
            <b-form-group label="Importe">
              <b-form-input type="number"
                            v-model="crud.formPrice.amount"
                            step="0.01"
                            placeholder="Ingresar el precio">
              </b-form-input>
            </b-form-group>            
          </b-col>
          <b-col md="3">                
            <b-button variant="dark" 
                      @click="saveFormPrice()"
                      title="Guardar Precio"
                      class="crud-courses-button-save-price pull-right">
              <b-icon icon="plus" v-if="crud.formPrice.id==0"></b-icon>
              <i class="fa fa-save" v-else></i>
            </b-button> 
            <b-button variant="outline-danger" 
                      @click="cancelSaveFormPrice()"
                      title="Cancelar Edición"
                      class="crud-courses-button-save-price pull-right mr-1"                      
                      v-if="crud.formPrice.id>0">
              <i class="fa fa-close"></i>
            </b-button>                                  
          </b-col>
          <b-col md="12">
            <b-table class="mb-0 crud-courses-table-prices"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tablePrices.items"
                    :fields="tablePrices.fields"                            
                    v-if="tablePrices.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 
              
              <template v-slot:cell(list)="data">                
                <div v-if="data.item.list">
                  {{data.item.list.name}}
                  <span v-if="data.item.list.public" class="crud-course-list-prices-public">
                    (Pública)
                  </span>
                </div>
              </template>                                                
              
              <template v-slot:cell(price)="data">
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right 
                            text="Acción" 
                            size="sm" 
                            variant="outline-dark" 
                            class="pull-right" 
                            :disabled="crud.formPrice.id>0">
                  <b-dropdown-item @click="editPricesCourses(data.item)">
                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="removePricesCourses(data.item)">
                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron precios para este Curso</b-alert>
          </b-col> 
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPrice.active=false">Salir</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'  
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Imagen from '@/components/inc/image/image' 
  import Param from '@/config/parameters'
  import { VueEditor } from "vue2-editor";
  import axios from 'axios'

  export default {
    components: {
      Imagen,
      FindObject,
      VueEditor,
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.CURSOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudCourses',
          elements: {}
        },         
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,
        },                
        tablePrices : {
          items: [],
          fields: [
            {key: 'list', label: 'Lista', class:"align-middle", width:"250px"},
            {key: 'price', label: 'Precio', class:"align-middle", width:"100px"},
            {key: 'f_action', label:'', class:"align-middle", width:"100px"},
          ]
        },                          
        crud: {
          form: {
            id: 0,
            code: '',
            name: '',                        
            subcategory: null,
            subcategory_id: 0,
            description: '',            
            description_short: '',     
            active: true,
            featured: false,
            image: [],            
            fileLink: '',
            file: null,
            videos: [],
            material: [],
            material_name: [],
            erp_iva_condition: null,
            erp_iva_condition_id: 0,            
          },           
          formPrice: {
            id: 0,
            courses_id: 0,
            price_list_id: 0,
            price_list_select: null,
            amount: 0,
            amount_old: 0
          },                                               
        },
        modal: {
          form: {
            active: false,
            title: ''
          },          
          formPrice: {
            active: false,
            title: ''
          },                                   
        },
        arr: {
          courses: [],          
          select:{
            listPrice: [],            
            categorys: [],
            subcategorys:[],
            active:[
              {code:true, label: 'Activo'},
              {code:false, label: 'Inactivo'},              
            ],
          }          
        },   
        filters: {
          categorys: [],
          subcategorys: [],
          active: {code:true, label: 'Activo'},
        },
        itemSelected: null,         
        modulesActive: [], 
        modules: {
          showPrices: false
        },      
        videosTMP: [],
        materialTMP: [],
        materialNameTMP: [],
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.modulesActive = Session.getSession().auth.user.permissions.modules
      this.configAccess()

      this.loadFieldTable()
      this.filterCourses()
      this.filterLoad()
      this.loadListPrice() 
    },
    computed: {
      isProcessSales() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.VENTAS || element.id == Modules.PRESUPUESTOS) {
            status = true
          }
        })
        return status
      },        
    },
    methods: {
      loadFieldTable () {
        // TABLE CURSOS
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle text-center", width:"30px"})
        this.table.fields.push({key: 'image', label: 'Imagen', class:"align-middle text-center", width:"60px"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"250px"})              
        if(this.modules.showPrices) {
          this.table.fields.push({key: 'prices', label: 'Precio', class:"align-middle", width:"150px"})      
        }                  
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})        
      },
      getRowCount (items) {
        return items.length
      },     
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerCurso()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.courses = data

          this.table.items.forEach(element => {
            if(!element.active) {
              element._rowVariant = 'danger'  
            }
          });
          
          loader.hide()         
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });        
      },      
      loadListPrice() {
        var result = serviceAPI.obtenerListaPrecios()

        result.then((response) => {
          var data = response.data    

          data.forEach(element => {
            this.arr.select.listPrice.push({
              code: element.id,
              label: element.name
            })  
          });    
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });        
      },
      configAccess() {
        this.modulesActive.forEach(element => {          
          if(element.id == Modules.PRECIOS) {                          
            this.modules.showPrices = true                             
          }
        })
      },
  
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''         
        this.crud.form.subcategory_id = 0
        this.crud.form.code = ''
        this.crud.form.description = ''        
        this.crud.form.description_short = ''                      
        this.crud.form.active = true
        this.crud.form.featured = false
        this.crud.form.image = []        
        this.crud.form.file = null
        this.crud.form.fileLink = ''
        this.videosTMP = []
        this.materialTMP = []
        this.materialName = []

        this.crud.form.erp_iva_condition = null
        this.crud.form.erp_iva_condition_id = 0        
        
        this.modal.form.title = "Nuevo Curso"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name              
        this.crud.form.subcategory_id = item.subcategory_id
        this.crud.form.code = item.code        
        this.crud.form.description = item.description      
        this.crud.form.description_short = (item.description_short!=null) ? item.description_short : ''        
        this.crud.form.active = item.active
        this.crud.form.featured = item.featured
        this.crud.form.image = item.image        
        this.crud.form.file = null
        this.crud.form.fileLink = item.attach     
        
        this.videosTMP = []
        if(item.videos)
          this.videosTMP = JSON.parse(item.videos)
        
        this.materialNameTMP = []
        if(item.material_name)
          this.materialNameTMP = JSON.parse(item.material_name)

        this.materialTMP = []
        if(item.material) {
          item.material.forEach(element => {
            this.materialTMP.push({
              fileLink: element
            }) 
          });
        }         

        if(item.image) {          
          this.crud.form.image = []
          item.image.forEach(element => {
            this.crud.form.image.push({ path: element })
          });                    
        } else {
          this.crud.form.image = []
        }


        this.crud.form.erp_iva_condition = item.erp_iva_condition
        this.crud.form.erp_iva_condition_id = item.erp_iva_condition_id

        this.modal.form.title = "Editar Curso"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Curso',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarCurso(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterCourses()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {        
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        var file = new FormData();
        if(this.crud.form.fileLink) {
          file.append("file", this.crud.form.fileLink);
        } else {        
          if(this.crud.form.file) {          
            file.append("file", this.crud.form.file);
          }
        }  

        var materiales = new FormData();
        this.materialTMP.forEach((value, key) => {
          if(value.fileLink) {
            materiales.append("material", value.fileLink);
          } else {        
            if(value.file) {          
              materiales.append("material", value.file);
            }
          }  
        })

        this.crud.form.videos = JSON.stringify(this.videosTMP)
        this.crud.form.material_name = JSON.stringify(this.materialNameTMP)

        if (this.crud.form.id) {
          var result = serviceAPI.editarCurso(JSON.stringify(this.crud.form), image, file, materiales);
        } else {
          var result = serviceAPI.agregarCurso(JSON.stringify(this.crud.form), image, file, materiales);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filterCourses()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadCoursesSubcategory (object) {
        if(object){
          this.crud.form.subcategory = object
          this.crud.form.subcategory_id = object.id
        } else {
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0
        }
      },         
      loadIvaConditions (object) {
          if(object){
              this.crud.form.erp_iva_condition = object
              this.crud.form.erp_iva_condition_id = object.id             
          } else {
              this.crud.form.erp_iva_condition = null
              this.crud.form.erp_iva_condition_id = 0
          }
      },
            
      filterLoad() {
        this.filterLoadCategorys()
      },      
      filterLoadCategorys() {
        var result = serviceAPI.obtenerCategoria()        
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.select.categorys.push({code: element.id, label: element.name})                        
            /*
            element.subcategorys.forEach(element1 => {
              this.arr.select.subcategorys.push({category: element.name, subcategory: element1.name, code: element1.id})                        
            });
            */                                    
          });                                
        })   
      },   
      filterLoadSubCategorys(val) {
        this.arr.select.subcategorys = []
        if(val) {          
          var result = serviceAPI.obtenerCategoria()        
          result.then((response) => {
            var data = response.data    

            data.forEach(element => {          
              if(element.id == val.code) {
                element.subcategorys.forEach(element1 => {
                  this.arr.select.subcategorys.push({code: element1.id, label: element1.name})                        
                }); 
              }                                  
            });                                
          })   
        }
      },

      filterCourses(forceOpenPricesCourses=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarCurso(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data
          this.table.items = data.data          
          this.arr.courses = data.data          
                    
          this.table.items.forEach(element => {
            if(!element.active) {
              element._rowVariant = 'danger'                          
            }
            
            if(forceOpenPricesCourses) {
              if(element.id == this.itemSelected.id) {
                this.setPricesCourses(element)
              }              
            }              
          });         
                    
          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => {  
          this.table.isBusy = false
          this.$awn.alert(ErrorToken.valid(error));
        })  
      }, 

      setPricesCourses(item) {
        this.crud.formPrice.id = 0
        this.crud.formPrice.courses_id = item.id
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0

        this.tablePrices.items = item.prices
        this.itemSelected = item

        this.modal.formPrice.active = true
        this.modal.formPrice.title = "Mantenimiento de Precios"
      },
      editPricesCourses(item) {
        this.crud.formPrice.id = item.id
        this.crud.formPrice.courses_id = item.courses_id
        this.crud.formPrice.price_list_select = { code: item.list.id, label: item.list.name}
        this.crud.formPrice.price_list_id = item.list.id
        this.crud.formPrice.amount = item.amount_total      
        this.crud.formPrice.amount_old = item.amount_total
      },
      cancelSaveFormPrice(){
        this.crud.formPrice.id = 0
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0
      },
      removePricesCourses(item) {
        var id = item.id
        var listName = 'LISTA: ' + item.list.name        
        var listAmount = 'PRECIO: ' + Intl.NumberFormat('es-AR',{style:'currency',currency:item.list.currency.code}).format(item.amount_total)

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item de la ' + listName + ' con ' + listAmount + '?', {
          title: 'Borrar Precio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var result = serviceAPI.eliminarPrecio(id);

            result.then((response) => {      
              loader.hide()
              this.filterCourses(true)
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          } 
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      saveFormPrice(){
        let loader = this.$loading.show();

        if(this.crud.formPrice.price_list_select) {
          this.crud.formPrice.price_list_id = this.crud.formPrice.price_list_select.code
        }        

        if (this.crud.formPrice.id) {
          var result = serviceAPI.editarPrecio(this.crud.formPrice)
        } else {
          var result = serviceAPI.agregarPrecio(this.crud.formPrice)
        }

        result.then((response) => {                         
          loader.hide()
          this.filterCourses(true)          
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },   

      setConfigTableCoursesShow() {
        var arrTable = document.getElementsByClassName('table-courses-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableCoursesHide() {
        var arrTable = document.getElementsByClassName('table-courses-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      
      addItemVideoCourses() {
        this.videosTMP.push({
          link: ''
        })
      },
      deleteItemVideoCourses(item) {
        var i = this.videosTMP.indexOf( item );
    
        if ( i !== -1 ) {
            this.videosTMP.splice( i, 1 );
        }
      },
         
      addItemMaterialCourses() {
        this.materialTMP.push({
          file: null,
          fileLink: '',
        })
        this.materialNameTMP.push({          
          name: '',
        })        
      },
      deleteItemMaterialCourses(item) {
        var i = this.materialTMP.indexOf( item );
        if ( i !== -1 ) {
            this.materialTMP.splice( i, 1 );
            this.materialNameTMP.splice( i, 1 );
        }     
      },
      changeMaterial(index) {
        this.materialTMP[index].fileLink = '' 
        this.materialNameTMP[index].name = ''        
      }
    }    
  }
</script>
<style>
  .crud-courses-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-courses-code {
    color: gray;
  }
  .crud-course-image {
    height: 40px;
  }
  .crud-course-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }    
  .crud-courses-button-save-price {
    margin-top: 30px;
  }  
  .crud-course-list-prices-public {
    font-size: 10px;
    font-weight: 600;
    color: gray;
  }
  .crud-courses-table-prices {
    min-height: 100px;
  }   
</style>